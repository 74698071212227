import React from 'react';
import { Link } from 'react-router-dom';
import mountain from '.././black_mountain.png'; // Adjust the path if needed
import './NavBar.css';

function NavBar() {
  return (
    <>
      <nav className="NavBar">
        <Link to="/" className="logo">
          <img src={mountain} alt="Logo" />
          <span className="company-name">Summit AI</span>
        </Link>
        <ul className="nav-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/courses">Courses</Link></li>
          <li><Link to="/testimonials">Testimonials</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
      <div className="video-bar">
        <video src="navbar_video.mp4" autoPlay muted loop></video> {/* Update the video path */}
      </div>
    </>
  );
}

export default NavBar;
