import React from 'react';
import NavBar from './NavBar';
import './HomePage.css';
import aiImage1 from '../images/mountain_summit2.png'; // Example image paths
import aiImage3 from '../images/consulting.png'; // Example image paths
import aiImage2 from '../images/ai-image2.png'; // Example image paths
import aiImage4 from '../images/lecture.png'; // Example image paths
import aiImage5 from '../images/dataman.png'; // Example image paths


function ServicesPage() {
  return (
    <div className="HomePage">
      <NavBar />
      <div className="content">
        <h1>Our Services</h1>
        <div className="section-home">
          <p>At Summit AI, we offer a comprehensive range of services designed to help businesses leverage the power of artificial intelligence and data analytics. Our expert team is committed to delivering tailored solutions that meet the unique needs of each client.</p>
          <img src={aiImage1} alt="AI Concept 1" className="ai-image" />
        </div>
        <div className="section-home reverse">
          <p><strong>AI Consulting:</strong> Our AI consulting services are designed to help businesses integrate artificial intelligence into their operations. We provide strategic advice, develop custom AI models, and implement solutions that drive efficiency and innovation.</p>
          <img src={aiImage2} alt="AI Consulting" className="ai-image" />
        </div>
        <div className="section-home">
          <p><strong>Data Consulting:</strong> Our data consulting services focus on helping businesses harness the power of their data. We offer data strategy development, data architecture design, and advanced analytics to unlock valuable insights and drive informed decision-making.</p>
          <img src={aiImage3} alt="Data Consulting" className="ai-image" />
        </div>
        <div className="section-home reverse">
          <p><strong>AI and Data Education:</strong> We offer short workshops to help your team understand and implement AI and Data technologies at a range of levels. Our hands-on training workshops cover a range of topics in data and AI, from basic data to advanced implimentation of AI solutions .</p>
          <img src={aiImage4} alt="AI Education" className="ai-image" />
        </div>

        <div className="section-home">
          <p><strong>Combination Packages:</strong> We offer combination packages that include both consulting and education services. These packages are designed to provide a holistic approach to AI and data integration, ensuring your team is fully equipped to drive innovation and efficiency.</p>
          <img src={aiImage5} alt="Combination Packages" className="ai-image" />
        </div>
      </div>
    </div>
  );
}

export default ServicesPage;
