// import React from 'react';
// import NavBar from './NavBar';
// import './CoursesPage.css';
// import aiIntroImg from '../images/accessibleAI.png';
// import machineLearningImg from '../images/copilot.png';
// import deepLearningImg from '../images/openaiapi.png';


// function Courses() {
//   const courses = [
//     {
//       name: 'Accessible AI for Workplace Productivity',
//       description: "In this course, you'll learn how to access, use, and apply current-generation AI tools safely and effectively, empowering you to significantly enhance your work and boost efficiency in your role. You'll develop the creative thinking skills needed to make these powerful tools work for you, no matter your industry or position. Through engaging case studies and demonstrations, you'll gain hands-on experience with immediately accessible examples, allowing you to see firsthand the remarkable potential of AI to improve processes, increase productivity, and foster innovation in your organization.",
//       image: aiIntroImg
//     },
//     {
//       name: 'Leveraging Copilot in Your Workplace',
//       description: [
//         'Content Coming'
//       ],
//       image: machineLearningImg
//     },
//     {
//       name: 'Leveraging OpenAI API',
//       description: [
//         'A course designed to teach practical application and use cases for the OpenAI API',
//         'Text and Image Prompting from the API',
//         'Applying the API in Your DE Pipeline',
//         'Creating Your Custom Chatbot',
//         'Custom RAG',
//         'Your Own Project'
//       ],
//       image: deepLearningImg
//     }
//   ];

//   return (
//     <div className="CoursesPage">
//       <NavBar />
//       <div className="content">
//         <h1>Courses</h1>
//         {courses.map((course, index) => (
//           <div key={index} className="course-section">
//             <h2>{course.name}</h2>
//             <div className="course-content">
//               <ul className="course-description">
//                 {course.description.map((item, idx) => (
//                   <li key={idx}>{item}</li>
//                 ))}
//               </ul>
//               <img src={course.image} alt={course.name} className="course-image" />
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Courses;



import React from 'react';
import NavBar from './NavBar';
import './HomePage.css';
import aiIntroImg from '../images/accessibleAI.png';
import machineLearningImg from '../images/copilot.png';
import deepLearningImg from '../images/openaiapi.png';
import certImg from '../images/microsoft-ai.png';

function Courses() {
  return (
    <div className="HomePage">
      <NavBar />
      <div className="content">
        <h1>Courses</h1>

        <div className="section-home">
          <p><strong> Custom Courses</strong><br></br><br>
          </br>We can provide a range of tailored courses for your company / individual needs through data skills tobuilding AI applications. Below are some examples of current AI offerings </p>
         
        </div>

        <div className="section-home">
          <p><strong> Accessible AI for Workplace Productivity</strong><br></br><br>
          </br>In this course, you'll learn how to access, use, and apply current-generation AI tools safely and effectively, empowering you to significantly enhance your work and boost efficiency in your role. You'll develop the creative thinking skills needed to make these powerful tools work for you, no matter your industry or position. Through engaging case studies and demonstrations, you'll gain hands-on experience with immediately accessible examples, allowing you to see firsthand the remarkable potential of AI to improve processes, increase productivity, and foster innovation in your organisation. Please email for more details on content and pricing </p>
          <img src={aiIntroImg} alt="AI Concept 1" className="ai-image" />
        </div>
        <div className="section-home">
          <p><strong> Leaveraging Microsoft Copilot for Workplace efficiency</strong><br></br><br></br>
          This course will focus on how you can make best use of copilot in your every day office tasks in order to maximise your efficiency and imporve your output. Please email for more details on content and pricing </p>
          <img src={machineLearningImg} alt="AI Concept 2" className="ai-image" />
        </div>
        <div className="section-home">
        <p><strong> Leaveraging OpenAI's API in your data pipeline</strong><br></br><br></br>
        This course will focus on how you can connect to the OpenAI API. We will introduce the different options available for working with various forms of unstructured data. We will look at how you can integrate this into your data pipeline. We will also explore how you can create chatbots that utilise your company's own material, as well as various other use cases. Please email us for more details on content and pricing.</p>
          <img src={deepLearningImg} alt="AI Concept 3" className="ai-image" />
        </div>
        
        <div className="section-home">
          <p><strong> Certification Courses</strong><br></br><br>
          </br> We can provide training to help you meet your goals becoming certified in various cloud platform qualifications with mainstream providers such as Azure as well as working towards other qualifications e.g PowerBI PL-300 / AI -900 AI fundermentals. </p>
          <img src={certImg} alt="cert_image" className="certImg" />
         
        </div>
      </div>
    </div>
  );
}

export default Courses;
