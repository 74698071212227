import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ServicesPage from './components/ServicesPage';
import ContactPage from './components/ContactPage';
import CoursesPage from './components/CoursesPage';
import Testomonials from './components/Testimonials';
import App from './App';
function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/courses" element={<CoursesPage />} />
        <Route path="/testimonials" element={<Testomonials />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;


// src/AppRouter.js
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import App from './App';
// import HomePage from './components/HomePage';  // Correct import path

// function AppRouter() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<App />} />
//         <Route path="/home" element={<HomePage />} />
//       </Routes>
//     </Router>
//   );
// }
