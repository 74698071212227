import React from 'react';
import NavBar from './NavBar';
import './ContactPage.css';
import peteImg from '../images/pete_img.png'; // Example image path

function ContactPage() {
  return (
    <div className="ContactPage">
      <NavBar />
      <div className="contentCONTACT">
        <h1>Contact</h1>
        
        <img src={peteImg} alt="Pete" className="ai-image" />
        <div className="section3">
          <p>To discuss pricing or further detail on any of our services contact Pete : <br></br><br></br>
        <a href="mailto:summitai.pete@gmail.com">summitai.pete@gmail.com</a><br></br>
        <br></br>We will get back to you shortly.</p>
        </div>

      </div>
    </div>
  );
}



export default ContactPage;


