// import React from 'react';
// import NavBar from './NavBar';
// import './CoursesPage.css';
// import aiIntroImg from '../images/accessibleAI.png';
// import machineLearningImg from '../images/copilot.png';
// import deepLearningImg from '../images/openaiapi.png';


// function Courses() {
//   const courses = [
//     {
//       name: 'Accessible AI for Workplace Productivity',
//       description: "In this course, you'll learn how to access, use, and apply current-generation AI tools safely and effectively, empowering you to significantly enhance your work and boost efficiency in your role. You'll develop the creative thinking skills needed to make these powerful tools work for you, no matter your industry or position. Through engaging case studies and demonstrations, you'll gain hands-on experience with immediately accessible examples, allowing you to see firsthand the remarkable potential of AI to improve processes, increase productivity, and foster innovation in your organization.",
//       image: aiIntroImg
//     },
//     {
//       name: 'Leveraging Copilot in Your Workplace',
//       description: [
//         'Content Coming'
//       ],
//       image: machineLearningImg
//     },
//     {
//       name: 'Leveraging OpenAI API',
//       description: [
//         'A course designed to teach practical application and use cases for the OpenAI API',
//         'Text and Image Prompting from the API',
//         'Applying the API in Your DE Pipeline',
//         'Creating Your Custom Chatbot',
//         'Custom RAG',
//         'Your Own Project'
//       ],
//       image: deepLearningImg
//     }
//   ];

//   return (
//     <div className="CoursesPage">
//       <NavBar />
//       <div className="content">
//         <h1>Courses</h1>
//         {courses.map((course, index) => (
//           <div key={index} className="course-section">
//             <h2>{course.name}</h2>
//             <div className="course-content">
//               <ul className="course-description">
//                 {course.description.map((item, idx) => (
//                   <li key={idx}>{item}</li>
//                 ))}
//               </ul>
//               <img src={course.image} alt={course.name} className="course-image" />
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Courses;



import React from 'react';
import NavBar from './NavBar';
import './HomePage.css';
import './Testimonials.css';
import aiIntroImg from '../images/accessibleAI.png';
import machineLearningImg from '../images/copilot.png';
import deepLearningImg from '../images/openaiapi.png';

function testimonial() {
  return (

    <div className="HomePage">
    <NavBar />
    <div className="content">
      <h1>Testimonials</h1>

    <div className="CoursesPage">
      <div className="content">
        <p className="quote"><em>"A great introduction to an area I have been interested in..."</em> - Introduction to AI and LLMs for IFAs</p>
        <br></br>
        <p className="quote"><em>"I had some idea about how AI works but this has been a real eye opener. "  Introduction to AI and LLMs for IFAs</em></p>
        <br></br>
        <p className="quote"><em>"Through Pete's expert guidance, I've learned to harness the free versions of multiple online AI platforms to exponentially boost my professional productivity. Pete's deep understanding of data privacy considerations ensured that any shared information was handled appropriately and he provided creative ways to leverage AI without the need to integrate it with my organisation's IT systems. This has been invaluable for scheduling, generating reports, analysing data, creating tailored summaries for different audiences and drafting communications. His balanced, well-informed approach, free from bias and hype, has transformed me from a hesitant beginner into an empowered, informed user. If you want an honest, practical understanding of AI's true capabilities and limitations, I highly recommend his services. He will help you explore AI's potential and navigate its challenges, encouraging you to test and understand the system's boundaries for yourself. " AI for Workplace Productivity</em></p>
        <br></br>
        <p className="quote"><em>"Good grounding in how to form tasks for LLMs to work on. Great initial session on LLMs, would highly recommend " Introduction to AI and LLMs for IFAs(2) </em></p>
       
      </div>
    </div>
    </div>
    </div>
  );
}


export default testimonial;
